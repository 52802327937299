.silent .channel > canvas {
    background: #4D4D4D;
}

.channel canvas {
    background: rgba(255, 255, 255, 0.80)
}

.playlist-time-scale {
    padding: 4px;
    position: sticky!important;
}

.playlist-time-scale .time {
    font-size: 12px;
    font-family: DM Sans, sans-serif;
    color: rgba(255, 255, 255, 0.30)
}

.playlist-time-scale > :first-child {
    display: none;
}
.playlist-time-scale {
    position: sticky;
    z-index: 99;
    top: 0;
    background: black;
    padding-left: 0;
    height: 61px;
    border-bottom: 1px solid #494949;
}

@media (max-width: 900px) {
    .playlist-time-scale {
        height: 73px;
    }
}

.playlist-tracks {
    overflow-x: unset;
    margin-top: 0;
}
.playlist .channel {
    background: rgba(255, 255, 255, 0.80);
}

.playlist .state-cursor {
    border-bottom: 1px solid #494949;
}

.waveform .cursor {
    background: #008EF3;
    width: 2px !important;
}
.playlist-tracks .channel-wrapper:first-child .waveform .cursor::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url("../../../public/assets/multiTrackCursor.png") no-repeat center;
    position: absolute;
    top: -1px;
    background-size: contain;
    left: -7px;
    z-index: 999;
}

.playlist .channel-progress {
    background: blue;
}

.playlist .vocals {
    background: rgba(0, 0, 0, 0.10);
}
