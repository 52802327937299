.resizeHandle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1px;
  flex-grow: 1;
}

.resizeMainSongPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1px;
}